import React, { useEffect, useState } from 'react';
import Container from '../../shared/components/containerTemplate/Container';
import TableResponsive, { TablePaginationProps } from '../../shared/components/tables/TableResponsive';
import { GetAllUserResponseContract } from './Contracts';
import { HomeService } from './HomeService';
import { useAppSelector } from '../../shared/store/hooks';
import { connectionPendings } from '../../shared/store/slices/loadingConnectionSlice';

const Home = () => {
  const loading = useAppSelector(connectionPendings);

  const [empresaData, setEmpresaData] = useState<GetAllUserResponseContract[] | undefined>([]);
  const [pagination, setPagination] = useState<TablePaginationProps>({
    currentPage: 1,
    totalPages: 1,
    totalRecords: 10,
  });

  const fetchUsers = async (page: number, pageSize: number) => {
    var response = await HomeService.GetAllUser(page, pageSize);
    if (response?.data) {
      setEmpresaData(response.data);
      setPagination({
        currentPage: response.pagination.currentPage,
        totalPages: response.pagination.totalPages,
        totalRecords: response.pagination.pageSize,
      });    }
  };

  const handlePageChange = (page: number) => {
    fetchUsers(page, pagination.totalRecords);
  };

  const handlePageSizeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newSize = parseInt(event.target.value, 10);
    setPagination({
      currentPage: pagination.currentPage,
      totalPages: pagination.totalPages,
      totalRecords: newSize,
    }); // Reiniciar a la primera página
    fetchUsers(1, newSize); // Volver a la primera página con el nuevo tamaño de página
  };

  useEffect(() => {
    fetchUsers(1, pagination.totalRecords);
  }, []);

  return (
    <Container title="Usuarios activos">
      <div>
        <div className="ms-2 mb-2">
          <label htmlFor="pageSize" className="mr-2">
            Tamaño de página:
          </label>
          <select
            id="pageSize"
            value={pagination.totalRecords}
            onChange={handlePageSizeChange}
            className="border p-1"
          >
            <option value={10}>10</option>
            <option value={15}>15</option>
            <option value={20}>20</option>
          </select>
        </div>

        <TableResponsive
          values={empresaData}
          pagination={pagination}
          onPage={(page) => handlePageChange(page)}
          isLoading={loading.includes(HomeService.GetUrlComplete.GetAllUser)}
          orderColumn={{empresa: 3}}
          removeColumn={["id"]}
        />
      </div>
    </Container>
  );
};

export default Home;