const Login = () => {
    return (
        <section className="w-full h-screen bg-gray-100 flex items-center justify-center">
            <div className="w-[80%] h-auto bg-white shadow-lg rounded-lg flex flex-col md:flex-row">
                <div className="w-full md:w-1/2 p-4 flex items-center justify-center">
                    <img 
                        src="/images/gearDalle.webp" 
                        className="max-w-full max-h-full object-contain" 
                        alt="imagen decorativa para pantalla de login"
                    />
                </div>
                <div className="w-full md:w-1/2 p-8 flex flex-col items-center justify-center">
                    <h1 className="text-2xl md:text-3xl font-bold text-gray-800 mb-6 text-center">Bienvenido al backoffice de AUDITRIX</h1>
                    <button className="px-6 py-3 bg-cyan-600 text-white rounded-lg hover:bg-cyan-700 transition duration-300">Login</button>
                </div>
            </div>
        </section>
    );
}

export default Login;
