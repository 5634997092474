import ApiService from "../../apiService/api";
import { BaseResponse } from "../../wrappers/BaseResponse";
import { GetAllGearGeneralResponseDto } from "./Contracts";

const urlBase = "engranajetipo";
const endpoints = {
    GetAllGearGeneral: 'GetAllGearGeneral'
}

const GetAllGearGeneral = async () : Promise<BaseResponse<GetAllGearGeneralResponseDto[]> | undefined> =>{
    let finalUrl = urlBase+"/"+endpoints.GetAllGearGeneral;
    var response = await ApiService.get<GetAllGearGeneralResponseDto[]>(finalUrl)

    return response;
}

const GetUrlComplete = {
    GetAllGearGeneral: `${urlBase}/${endpoints.GetAllGearGeneral}`.toLowerCase()
}

export const EngranajeGeneralService = {
    GetAllGearGeneral,
    GetUrlComplete
}