import { HideModal, ShowModal } from "../../../shared/store/slices/modalSlice";
import { store } from "../../../shared/store/store";
import CompanyResultModal from "./components/CompanyResultModal";
import ErrorConnectionModal from "./components/ErrorConnectionModal";
import QuestionAddModal from "./components/QuestionAddModal";
import QuestionDeleteModal from "./components/QuestionDeleteModal";
import QuestionEditModal from "./components/questionEditModal/QuestionEditModal";
import TeethObservationModal from "./components/TeethObservationModal";

const ErrorConnection = () =>{
    const name = "ErrorConnectionModal";
    store.dispatch(ShowModal(name))
}

const QuestionEdit = () =>{
    const name = "QuestionEditModal";
    store.dispatch(ShowModal(name))
}

const QuestionDelete = () =>{
    const name = "QuestionDeleteModal";
    store.dispatch(ShowModal(name))
}

const QuestionAdd = () =>{
    const name = "QuestionAddModal";
    store.dispatch(ShowModal(name))
}

const CompanyResult = () =>{
    const name = "CompanyResultModal";
    store.dispatch(ShowModal(name))
}

const TeethObservation = () =>{
    const name = "TeethObservationModal";
    store.dispatch(ShowModal(name))
}

const closeModal = (name: string) =>{
    store.dispatch(HideModal(name));
}


export const modalRegistered : modalRegisteredProps = {
    ErrorConnectionModal,
    QuestionEditModal,
    QuestionDeleteModal,
    QuestionAddModal,
    CompanyResultModal,
    TeethObservationModal
}

export const modalService = {
    ErrorConnection,
    QuestionEdit,
    QuestionDelete,
    QuestionAdd,
    closeModal,
    CompanyResult,
    TeethObservation
}

interface modalRegisteredProps{
    [key: string]: any;
}