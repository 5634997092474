import ApiService from "../../apiService/api";
import { BaseResponse } from "../../wrappers/BaseResponse";
import { CreateQuestionByTeethIdRequest, GetQuestionAnswersResponseDto, GetQuestionByIdDto, GetQuestionByTeethIdFilterResponseDto } from "./Contracts";

const urlBase = "pregunta";
const endpoints = {
    GetAll: 'GetAll',
    GetQuestionByTeethIdFilter: 'GetQuestionByTeethIdFilter',
    GetQuestionById: 'GetQuestionById',
    DeleteQuestionById: 'DeleteQuestionById',
    CreateQuestionByTeethId: 'CreateQuestionByTeethId',
    SaveOrUpdateAnswer: 'SaveOrUpdateAnswer'

}

const GetAll = async () : Promise<BaseResponse<GetQuestionAnswersResponseDto[]> | undefined> =>{
    let finalUrl = urlBase+"/"+endpoints.GetAll;
    var response = await ApiService.get<GetQuestionAnswersResponseDto[]>(finalUrl)

    return response;
}

const GetQuestionByTeethIdFilter = async (teethId: number) : Promise<BaseResponse<GetQuestionByTeethIdFilterResponseDto[]> | undefined> =>{
    let finalUrl = urlBase+"/"+endpoints.GetQuestionByTeethIdFilter;
    var response = await ApiService.get<GetQuestionByTeethIdFilterResponseDto[]>(finalUrl, {teethId})

    return response;
}

const DeleteQuestionById = async (questionId: number) : Promise<BaseResponse<number> | undefined> =>{
    let finalUrl = urlBase+"/"+endpoints.DeleteQuestionById;
    var response = await ApiService.remove<number>(finalUrl, {questionId})

    return response;
}

const GetQuestionById = async (questionId: number) : Promise<BaseResponse<GetQuestionByIdDto> | undefined> =>{
    let finalUrl = urlBase+"/"+endpoints.GetQuestionById;
    var response = await ApiService.get<GetQuestionByIdDto>(finalUrl, {questionId})

    return response;
}

const CreateQuestionByTeethId = async (data: CreateQuestionByTeethIdRequest) : Promise<BaseResponse<number> | undefined> =>{
    let finalUrl = urlBase+"/"+endpoints.CreateQuestionByTeethId;
    var response = await ApiService.post<number>(finalUrl, null, data);

    return response;
}

const GetUrlComplete = {
    GetEmpresaInfo: `${urlBase}/${endpoints.GetAll}`.toLowerCase(),
    GetQuestionByTeethIdFilter: `${urlBase}/${endpoints.GetQuestionByTeethIdFilter}`.toLowerCase(),
    GetQuestionById: `${urlBase}/${endpoints.GetQuestionById}`.toLowerCase(),
    DeleteQuestionById: `${urlBase}/${endpoints.DeleteQuestionById}`.toLowerCase(),
    CreateQuestionByTeethId: `${urlBase}/${endpoints.CreateQuestionByTeethId}`.toLowerCase(),
}

export const QuestionService = {
    GetAll,
    GetQuestionByTeethIdFilter,
    GetQuestionById,
    DeleteQuestionById,
    CreateQuestionByTeethId,
    GetUrlComplete
}