import { configureStore } from "@reduxjs/toolkit";
import { loadingConnectionSlice } from "./slices/loadingConnectionSlice";
import { currentHomeSlice } from "./slices/home/currentHomeSlice";
import { gearAllSlice } from "./slices/gear/gearAllSlice";
import { tokenSlice } from "./slices/tokenSlice";
import { showToastSlice } from "./slices/showToastSlice";
import { modalSlice } from "./slices/modalSlice";
import { routerConfigSlice } from "./slices/routerConfigSlice";
import { currentQuestionSelectedSlice } from "./slices/questions/currentQuestionSelectedSlice";
import { currentTeethSelectedSlice } from "./slices/teeth/currentTeethSelectedSlice";
import { currentCompanySelectedSlice } from "./slices/company/currentCompanySelectedSlice";


//import { handlerNotificationSlice } from "./Slices/HandlerNotificationSlice";
// ...
export const store = configureStore({
  reducer: {
    loadingConnection: loadingConnectionSlice.reducer,
    currentHome: currentHomeSlice.reducer,
    allGear: gearAllSlice.reducer,
    token: tokenSlice.reducer,
    showToastSlice: showToastSlice.reducer,
    modalSlice: modalSlice.reducer,
    routerConfig: routerConfigSlice.reducer,
    currentQuestionSelected: currentQuestionSelectedSlice.reducer,
    currentTeethSelected: currentTeethSelectedSlice.reducer,
    currentCompanySelected: currentCompanySelectedSlice.reducer,


    // handlerNotification: handlerNotificationSlice.reducer,
  },
});
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;