import { useFormik } from "formik";
import { useEffect, useState } from "react";
import * as Yup from "yup";
import {
  GetAllGearGeneralResponseDto,
  GetAllGearGeneralTeethResponseDto,
} from "../../../shared/services/engranajesGenerales/Contracts";
import { EngranajeGeneralService } from "../../../shared/services/engranajesGenerales/EngranajeGeneralService";
import { useAppSelector } from "../../../shared/store/hooks";
import { connectionPendings } from "../../../shared/store/slices/loadingConnectionSlice";
import SimpleLoading from "../../../shared/IconSvg/Spinner/SimpleLoading";

const validationSchema = Yup.object({
  gearId: Yup.string(),
  teethId: Yup.string(),
});

const initialValues: QuestionFilterFormProps = {
  gearId: 1,
  teethId: 1,
};

const QuestionFilterForm = ({onSubmit} : QuestionFilterFormInterface) => {
  const loadingConnections = useAppSelector(connectionPendings);
  const [gears, setGears] = useState<GetAllGearGeneralResponseDto[]>();
  const [teeths, setTeeths] = useState<GetAllGearGeneralTeethResponseDto[]>();

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
        formik.setSubmitting(false);
        onSubmit(values);
    },
  });

  const handleChangeGear = (gearID: number) => {
    var teeths = handleTeethByGearId(gearID);
    setTeeths(teeths);

    if (teeths.length > 0) formik.setFieldValue("teethId", teeths[0].id);
  };

  const handleGetAllGears = async () => {
    var result = await EngranajeGeneralService.GetAllGearGeneral();

    if (!result?.hasError && result?.data) {
      setGears(result.data);
      if(result.data.length > 0 && result.data[0].dientes){
        setTeeths(result.data[0].dientes);

      }
    }
  };

  const handleTeethByGearId = (
    gearId: number
  ): GetAllGearGeneralTeethResponseDto[] => {
    var teeths: GetAllGearGeneralTeethResponseDto[] = [];
    if (gears) {
      teeths = gears.find((gear) => gear.id === Number(gearId))?.dientes ?? [];
    }

    return teeths;
  };

  //Modifico los teeths dependiendo de que gear se seleccionó.
  useEffect(() => {
    handleChangeGear(formik.values.gearId);
  }, [formik.values.gearId]);

  useEffect(() => {
    handleGetAllGears();
  }, []);

  return (
    <>

      {loadingConnections.includes(
        EngranajeGeneralService.GetUrlComplete.GetAllGearGeneral
      ) ? (
        <ul className="animate-pulse flex mb-3">
        <div className="bg-gray-200 rounded w-[8rem] h-[3rem] p-3 mr-3"></div>
        <div className="bg-gray-200 rounded w-[8rem] h-[3rem] p-3 mr-3"></div>
        <div className="bg-gray-200 rounded w-[5rem] h-[3rem] p-3"></div>
      </ul>
      ) : (
        <form
          onSubmit={formik.handleSubmit}
          className="w-full bg-white rounded"
        >
          <div className="flex mb-3">
            <div className="mr-2">
              <select
                {...formik.getFieldProps("gearId")}
                name="gearId"
                className="w-full px-5 py-3 text-base transition bg-transparent border rounded-md outline-none border-stroke dark:border-dark-3 text-body-color dark:text-dark-6 placeholder:text-dark-6 focus:border-primary dark:focus:border-primary focus-visible:shadow-none"
              >
                <option value="" disabled style={{ color: "#aaa" }}>
                  Engranaje
                </option>
                {gears?.map((item, index) => (
                  <option key={index} value={item.id}>
                    {item.nombre}
                  </option>
                ))}
              </select>
            </div>

            <div className="mr-3">
              <select
                {...formik.getFieldProps("teethId")}
                name="teethId"
                className="w-full px-5 py-3 text-base transition bg-transparent border rounded-md outline-none border-stroke dark:border-dark-3 text-body-color dark:text-dark-6 placeholder:text-dark-6 focus:border-primary dark:focus:border-primary focus-visible:shadow-none"
              >
                <option value="" disabled style={{ color: "#aaa" }}>
                  Diente
                </option>
                {teeths?.map((item, index) => (
                  <option key={index} value={item.id}>
                    {item.nombre}
                  </option>
                ))}
              </select>
            </div>

            <div>
              <input
                value="Aplicar"
                type="submit"
                className="w-full h-full px-3 text-base text-white transition duration-300 ease-in-out border rounded-md cursor-pointer border-primary bg-primary hover:bg-blue-dark"
                disabled={formik.isSubmitting}
              />
            </div>
          </div>
        </form>
      )}
    </>
  );
};

export default QuestionFilterForm;


export interface QuestionFilterFormInterface {
    onSubmit: (value: QuestionFilterFormProps) => void;
  }
  

export interface QuestionFilterFormProps {
  gearId: number;
  teethId: number;
}
