import { modalService } from "../ModalService";
import ModalTemplate from "../modalTemplates/ModalTemplate";
import { currentQuestionSelectedSelector } from "../../../store/slices/questions/currentQuestionSelectedSlice";
import { useAppSelector } from "../../../../shared/store/hooks";
import { QuestionService } from "../../../../shared/services/preguntas/QuestionService";
import { toastService } from "../../../../shared/components/toast/toastService";
import { messagesErrorToast } from "../../../../shared/components/toast/messagesErrorToast";

const QuestionDeleteModal = () => {
  const currentQuestionId = useAppSelector(currentQuestionSelectedSelector);

  const actionBotton = "Aceptar";
  const cancelBotton = "Cancelar";
  const level = "error";

  const handleOnRetry = async (value: boolean) => {
    if (value) {
      if (currentQuestionId.id) {
        var result = await QuestionService.DeleteQuestionById(currentQuestionId.id);
        if(result && result.data){
            toastService.success(messagesErrorToast.DATA_DELETED_SUCCESS);
        }
      }else{
        toastService.error(messagesErrorToast.DATA_NOT_FOUND);
      }
    }
    modalService.closeModal("QuestionDeleteModal");
  };

  return (
    <ModalTemplate
      level={level}
      actionBotton={actionBotton}
      cancelBotton={cancelBotton}
      onClicked={(value) => handleOnRetry(value)}
      title="¿Estás seguro de eliminar esta pregunta?"
    >
      <>
        <p>
          <span className="text-red-600">Ten cuidado:</span> La pregunta junto a
          sus respuestas seran eliminadas permanentemente
        </p>
        <p>Por favor confirma la acción</p>
      </>
    </ModalTemplate>
  );
};

export default QuestionDeleteModal;
