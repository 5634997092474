import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../store";

import { GearContract } from "../../../../features/listaEngranajes/Contract"
// Define the initial state using that type
interface props{
    id: number | undefined
    name: string | undefined
}
const initialState: props = {
    id: undefined,
    name: undefined
};
export const currentCompanySelectedSlice = createSlice({
  name: "currentCompanySelected",
  initialState,
  reducers: {
    SetCompanySelected: (state, action: PayloadAction<props | undefined>) => {
        if(action.payload !== null && action.payload){
            state.id = action.payload.id;    
            state.name = action.payload.name;    
        }
    }
  },
});
export const { SetCompanySelected } = currentCompanySelectedSlice.actions;
// Other code such as selectors can use the imported `RootState` type
export const currentCompanySelectedSelector = (state: RootState) => state.currentCompanySelected;
export default currentCompanySelectedSlice.reducer;