import { useState } from "react";
import { GetQuestionByIdAnswerDto } from "../../../../../shared/services/preguntas/Contracts";
import AddCommentIcon from "@mui/icons-material/AddComment";
import { toastService } from "../../../../../shared/components/toast/toastService";

const ANSWER_PERCENTAJE = [0, 25, 50, 75, 100];

const CreateQuestion = ({onCreateAnswer, allAnswer} : CreateQuestionProps) =>{
    const [newAnswer, setNewAnswer] = useState<GetQuestionByIdAnswerDto>();

    
  //Crea una respuesta
  // Variable que almacena las respuestas modificadas y nuevas
  // : allAnswer :
  //
  const handleNewAnswer = () => {
    if (
      newAnswer &&
      !Number.isNaN(newAnswer.porcentaje) &&
      newAnswer.respuesta.length > 0
    ) {
      var answerExist =
        allAnswer.find((x) =>
          x.respuesta.toLowerCase() === newAnswer.respuesta.toLowerCase() || x.porcentaje === newAnswer.porcentaje
      ) != null;


      var answerExistPercentaje =
        allAnswer.find((x) =>
          x.porcentaje === newAnswer.porcentaje
      ) != null;

      if(answerExistPercentaje){
        toastService.error("Ya se ha agregado una respuesta con ese porcentaje.");
        return;
      }

      if(answerExist){
        toastService.error("Esa respuesta ya está agregada.");
        return;
      }

      var newAnswerForSend = ([...allAnswer, newAnswer]);

      onCreateAnswer(newAnswerForSend);
      setNewAnswer({ id: 0, porcentaje: 0, respuesta: "" });
    } else {
      toastService.error("Necesitas agregar un contenido a la respuesta");
    }
  };

    return(
        <div className="flex items-center">
        <input
          type="text"
          name="name"
          onChange={(e) =>
            setNewAnswer({
              porcentaje: newAnswer?.porcentaje ?? 0,
              id: 0,
              respuesta: e.target.value,
            })
          }
          value={newAnswer?.respuesta ?? ""}
          className="inline w-[60%] sm:w-[80%] mr-3 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 p-2.5 "
          placeholder="Agregar nueva"
        />
        <select
          onChange={(e) =>
            setNewAnswer({
              porcentaje: Number(e.target.value),
              id: 0,
              respuesta: newAnswer?.respuesta ?? "",
            })
          }
          value={newAnswer?.porcentaje ?? 0}
          className="bg-gray-50 h-full border w-[5rem] border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 p-2.5 inline"
        >
          {ANSWER_PERCENTAJE.map((x, index) => (
            <option key={index} value={x}>
              {x}
            </option>
          ))}
        </select>
        <div
          className="inline ml-3 cursor-pointer"
          title="Agregar respuesta"
          onClick={() => handleNewAnswer()}
        >
          <AddCommentIcon className="text-green-400 font-[2rem]" />
        </div>
      </div>
    )
}

export default CreateQuestion;

interface CreateQuestionProps{
    onCreateAnswer: (value: GetQuestionByIdAnswerDto[]) => void;
    allAnswer: GetQuestionByIdAnswerDto[];
}