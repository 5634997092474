const AnswerInputSkeleton = () => {
  return (
    <ul className="animate-pulse">
      {[1, 2, 3, 4, 5, 6].map((e) => (
        <div key={e} className="flex item-center justify-center mb-3">
          <div className="bg-gray-200 rounded w-[35rem] h-[2.5rem]"></div>
          <div className="bg-gray-200 rounded w-[5rem] h-[2.5] ml-3"></div>
          <div className="bg-gray-200 rounded w-[1rem] h-[2.5] ml-3"></div>
        </div>
      ))}
    </ul>
  );
};

export default AnswerInputSkeleton;
