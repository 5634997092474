import ApiService from "../../shared/apiService/api";
import { store } from "../../shared/store/store";
import { BaseResponse } from "../../shared/wrappers/BaseResponse";
import { GetAllCompanySoliContract } from "./Contracts";
import { setsoliCompany } from "../../shared/store/slices/solicitudEmpresa/soliCompanySlice";
const urlBase = "solicitudempresa";
const endpoints = {
    GetAllSoliCompany: 'GetAllSoliCompany'
}

const GetAllSoliCompany = async (page: number, pageSize: number) : Promise<BaseResponse<GetAllCompanySoliContract[]> | undefined> =>{
    let finalUrl = urlBase+"/"+endpoints.GetAllSoliCompany;
    var response = await ApiService.get<GetAllCompanySoliContract[]>(finalUrl, {page, pageSize})

    //Guardo estado
    console.log(response?.data, "que tinee");
    store.dispatch(setsoliCompany(response?.data))
    return response;
}

const GetUrlComplete = {
    GetAllSoliCompany: `${urlBase}/${endpoints.GetAllSoliCompany}`.toLowerCase()
}

export const SoliCompanyService = {
    GetAllSoliCompany,
    GetUrlComplete
}