import { useEffect, useState } from "react";

const ModalFullScreenTemplate = ({
  children,
  title,
  onClicked
}: ModalFullScreenTemplateProps) => {
  return (
    <div
      tabIndex={-1}
      className="fixed top-0 left-0 inset-0 bg-gray-500 bg-opacity-75 transition-opacity flex justify-center items-center w-full h-full z-50"
    >
      <div className="relative w-full max-h-screen max-w-screen overflow-y-auto p-4">
        {/* Modal content */}
        <div className="relative bg-white rounded-lg shadow dark:bg-gray-700 h-full">
          {/* Modal header */}
          <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
            <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
              {title}
            </h3>
            <button
              onClick={() => onClicked && onClicked(false)}
              type="button"
              className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
              data-modal-toggle="crud-modal"
            >
              <svg
                className="w-3 h-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 14"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                />
              </svg>
              <span className="sr-only">Close modal</span>
            </button>
          </div>
          {/* Modal body */}
          {children}
        </div>
      </div>
    </div>
  );
};

export default ModalFullScreenTemplate;

export interface ModalFullScreenTemplateProps {
  title: string;
  children: React.ReactNode;
  onClicked?: (value: boolean) => void;
}
