import { modalRegistered, modalService } from "../ModalService";
import ModalTemplate from "../modalTemplates/ModalTemplate";

const ErrorConnectionModal = () => {

    
    const actionBotton = "Aceptar";
    const cancelBotton = "Cancelar";
    const level = "error";

    const handleOnRetry = (value: boolean) =>{
        console.log("Arar");
        modalService.closeModal("ErrorConnectionModal");
    }

  return (
    <ModalTemplate 
    level={level}
    actionBotton={actionBotton} 
    cancelBotton={cancelBotton}
    onClicked={(value) => handleOnRetry(value)}
    title="Conexión perdida o el servidor no responde" >
        <>
        <p>Tú conexión o la del servidor está devolviendo fallas de conexión</p>
        <p>Por favor prueba reintentar nuevamente.</p>
        </>
    </ModalTemplate>
  );
};

export default ErrorConnectionModal;