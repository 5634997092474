import { ReactElement, useEffect, useState } from 'react';
import { DienteTypeContract } from '../../../shared/services/engranajesGenerales/Contracts';

interface CustomAccordionProps {
  title: ReactElement;
  children: ReactElement;
  onClick?: (id: number) => void;
  withoutIcon?: boolean;
  className?: string;
}


function AccordionGeneric({ title, children, onClick, withoutIcon = false, className }: CustomAccordionProps) {
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);
  const [icon, setIcon] = useState<ReactElement>();

  const toggleAccordion = () => {
    setIsAccordionOpen(!isAccordionOpen);
  };

  const handleClick = (link: DienteTypeContract) => {
    onClick && onClick(link.id); // Llamar a la función del padre con el ID seleccionado
  };

  const handleSetIcon = ()=>{
    if(!withoutIcon){
        setIcon(
            <svg
              className="flex-shrink-0 size-4"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <rect width="20" height="14" x="2" y="7" rx="2" ry="2" />
              <path d="M16 21V5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v16" />
            </svg>
            )
    }
  }

  useEffect(() =>{
    handleSetIcon();
  },[])

  return (
    <div className="hs-accordion my-4">
      <button
        type="button"
        className={`hs-accordion-toggle w-full text-start flex items-center gap-x-3.5 py-2 px-2.5 hs-accordion-active:text-blue-600 hs-accordion-active:hover:bg-transparent text-sm text-neutral-700 rounded-t-lg hover:bg-gray-100 dark:bg-neutral-800 dark:hover:bg-neutral-700 dark:text-neutral-400 dark:hover:text-neutral-300 dark:hs-accordion-active:text-white ${className}`}
        onClick={toggleAccordion}
      >
        {icon}

        <div className='p-3'>
            {title}
        </div>

        <svg
          className={`hs-accordion-active:block ms-auto size-4 ${
            isAccordionOpen ? '' : 'hidden'
          }`}
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path d="m18 15-6-6-6 6" />
        </svg>

        <svg
          className={`hs-accordion-active:hidden ms-auto size-4 ${
            isAccordionOpen ? 'hidden' : 'block'
          }`}
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path d="m6 9 6 6 6-6" />
        </svg>
      </button>
      <div
        className={`hs-accordion-content w-full overflow-hidden transition-[height] duration-300 ${
          isAccordionOpen ? 'block' : 'hidden'
        }`}
      >
        <div className='relative'>
          {children}
        </div>
      </div>
    </div>
  );
}


export default AccordionGeneric;
