import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../store";

import { GearContract } from "../../../../features/listaEngranajes/Contract"
// Define the initial state using that type
interface props{
    id: number | undefined
}
const initialState: props = {
    id: undefined
};
export const currentQuestionSelectedSlice = createSlice({
  name: "currentQuestionSelected",
  initialState,
  reducers: {
    SetQuestionSelected: (state, action: PayloadAction<props | undefined>) => {
        if(action.payload !== null && action.payload){
            state.id = action.payload.id;    
        }
    }
  },
});
export const { SetQuestionSelected } = currentQuestionSelectedSlice.actions;
// Other code such as selectors can use the imported `RootState` type
export const currentQuestionSelectedSelector = (state: RootState) => state.currentQuestionSelected;
export default currentQuestionSelectedSlice.reducer;