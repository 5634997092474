import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import NotFound from "./features/notFound/NotFound";
import { AuthenticationService } from "./shared/authentication/AuthenticationService";
import PageTitle from "./shared/components/pageTitle/PageTitle";
import { toastTypes } from "./shared/components/toast/toastService";
import { useAppSelector } from "./shared/store/hooks";
import { ShowToastSelector } from "./shared/store/slices/showToastSlice";
import Routers from "./shared/routeConfig/routeConfig";
import Aside from "./shared/layout/Aside";

function App() {
  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const showToast = useAppSelector(ShowToastSelector);

  const notify = () => {
    if (showToast.content) {
      switch (showToast.type) {
        case toastTypes.error:
          toast.error(showToast.content, showToast.options);
          break;

        case toastTypes.info:
          toast.info(showToast.content, showToast.options);
          break;

        case toastTypes.success:
          toast.success(showToast.content, showToast.options);
          break;

        case toastTypes.warning:
          toast.warning(showToast.content, showToast.options);
          break;
      }
    }
  };

  //Obtencion y guardado de token.
  const saveToken = async () => {
    var token = await getAccessTokenSilently();
    AuthenticationService.saveAuthentication(token);
  };

  useEffect(() => {
    if (isAuthenticated) {
      saveToken();
    }
    console.log(user?.sub, "user?.sub");
  }, [user?.sub]);

  useEffect(() => {
    notify();
  }, [showToast]);

  return (
    <div>
      {/* SideBar */}
      <div>
        <Aside />
      </div>

      {/* Contenido / Body */}
      <div className="sm:ml-[16rem] mt-10">
        <Routers/>
      </div>
    </div>
  );
}

export default App;
