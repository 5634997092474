const ErrorMessage = ({formik, keyForm} : ErrorMessageProps) =>{

    return(
        <>
        {formik.touched[keyForm] && formik.errors[keyForm] ? (
                <div style={{ color: 'red' }}>{formik.errors[keyForm]}</div>
                ) : null}
        </>
    )
}

export default ErrorMessage;

interface ErrorMessageProps{
    formik: any;
    keyForm: string;
}