import { useEffect, useState } from "react";
import QuestionFilterForm from "../../../../features/questions/components/QuestionFilterForm";
import { GetDienteObservacionResponseDto } from "../../../../shared/services/dientesObservaciones/Contracts";
import { TeethObservationService } from "../../../../shared/services/dientesObservaciones/TeethObservationService";
import { useAppSelector } from "../../../store/hooks";
import { currentCompanySelectedSelector } from "../../../store/slices/company/currentCompanySelectedSlice";
import { connectionPendings } from "../../../store/slices/loadingConnectionSlice";
import SimpleCard from "../../card/SimpleCard";
import Container from "../../containerTemplate/Container";
import { modalService } from "../ModalService";
import ModalFullScreenTemplate from "../modalTemplates/ModalFullScreenTemplate";

const DEFAULT_TEETHID = 1;


const TeethObservationModal = () => {
  const currentCompanySelected = useAppSelector(currentCompanySelectedSelector);
  const loadingConnections = useAppSelector(connectionPendings);
  const [observaciones, setObservaciones] = useState<GetDienteObservacionResponseDto[]>();

  const handleOnRetry = (value: boolean) => {
    modalService.closeModal("TeethObservationModal");
  };

  const GetObservations = async (teethId: number = DEFAULT_TEETHID) => {
    if(currentCompanySelected.id){

      var result = await TeethObservationService.GetDienteObservacion(currentCompanySelected.id, teethId);

      if(result && result.data){
        setObservaciones(result.data);
      }
    }
  }

  useEffect(() =>{
    GetObservations();
  }, [])

  return (
    <ModalFullScreenTemplate
      onClicked={(value) => handleOnRetry(value)}
      title="Observaciones"
    >
      <Container title="Filtra por diente">
        <QuestionFilterForm onSubmit={(values) => GetObservations(values.teethId)} />

        <div className="flex flex-wrap items-center justify-center">
          {observaciones?.map((e,index) => (
            <SimpleCard key={index} title={e.pregunta} className="m-2">
              <p>
                {e.observacion}
              </p>
            </SimpleCard>
          ))}
        </div>
      </Container>
    </ModalFullScreenTemplate>
  );
};

export default TeethObservationModal;
