import { useFormik } from "formik";
import * as Yup from "yup";
import ErrorMessage from "../../../../shared/components/form/ErrorMessage";
import { messagesErrorToast } from "../../../../shared/components/toast/messagesErrorToast";
import { toastService } from "../../../../shared/components/toast/toastService";
import { CreateQuestionByTeethIdRequest } from "../../../../shared/services/preguntas/Contracts";
import { QuestionService } from "../../../../shared/services/preguntas/QuestionService";
import { useAppSelector } from "../../../../shared/store/hooks";
import { currentTeethSelectedSelector } from "../../../../shared/store/slices/teeth/currentTeethSelectedSlice";
import { modalService } from "../ModalService";
import ModalTemplate from "../modalTemplates/ModalTemplate";

const INITIAL_VALUES = {
  question: "",
};

const VALIDATION_SCHEMA = Yup.object({
  question: Yup.string().required("Campo obligatorio"),
});

const QuestionAddModal = () => {
  const currentTeethId = useAppSelector(currentTeethSelectedSelector);

  const actionBotton = undefined;
  const cancelBotton = "Cancelar";
  const level = "info";

  const formik = useFormik({
    initialValues: INITIAL_VALUES,
    validationSchema: VALIDATION_SCHEMA,
    onSubmit: (values) => handleCreateNewQuestion(values.question),
  });

  const handleOnRetry = async (value: boolean) => {
    modalService.closeModal("QuestionAddModal");
  };

  const handleCreateNewQuestion = async (question: string) =>{

    if(currentTeethId.id){
        var data : CreateQuestionByTeethIdRequest = {
            contenido: question,
            dienteGeneralId: currentTeethId.id,
            nivelAuditoriaId: 1
        }

        var result = await QuestionService.CreateQuestionByTeethId(data);

        if(result && result.data){
            toastService.success(messagesErrorToast.DATA_SAVED_SUCCESS);
        }

        handleOnRetry(true);
    }else{
        toastService.error(messagesErrorToast.DATA_NOT_FOUND);
    }

  }

  return (
    <ModalTemplate
      level={level}
      actionBotton={actionBotton}
      cancelBotton={cancelBotton}
      onClicked={(value) => handleOnRetry(value)}
      title="Crear una pregunta"
    >
      <>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            formik.submitForm();
          }}
        >
          <div>
            <input
              title="Crea una pregunta"
              {...formik.getFieldProps("question")}
              type="text"
              name="question"
              className="inline w-[20rem] bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
              placeholder="Crea una pregunta"
            />
          <ErrorMessage formik={formik} keyForm="question" />
          </div>
          <input
            value="Crear"
            type="submit"
            className="w-full mt-3 sm:w-[6rem] sm:ml-3 p-2 sm:float-right h-full px-3 text-base text-white transition duration-300 ease-in-out border rounded-md cursor-pointer border-primary bg-primary hover:bg-blue-dark"
            disabled={formik.isSubmitting}
          />
        </form>
      </>
    </ModalTemplate>
  );
};

export default QuestionAddModal;
