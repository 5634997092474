import { setToken } from "../store/slices/tokenSlice";
import { store } from "../store/store";

const saveAuthentication = (token: string) =>{
    try {
        store.dispatch(setToken(token));
      } catch (e) {
        console.log(e);
      }
}

export const AuthenticationService = {
    saveAuthentication
}