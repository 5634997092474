import ApiService from "../../apiService/api";
import { store } from "../../store/store";
import { BaseResponse } from "../../wrappers/BaseResponse";
import { GetEmpresaInfoContractResponse } from "./Contract";

const urlBase = "cantidadempleado";
const endpoints = {
    GetCantidadEmpleados: 'GetAll'
}

const GetCantidadEmpleados = async () : Promise<BaseResponse<GetEmpresaInfoContractResponse[]> | undefined> =>{
    let finalUrl = urlBase+"/"+endpoints.GetCantidadEmpleados;
    var response = await ApiService.get<GetEmpresaInfoContractResponse[]>(finalUrl)

    return response;
}



const GetUrlComplete = {
    GetCantidadEmpleados: `${urlBase}/${endpoints.GetCantidadEmpleados}`.toLowerCase()
}

export const CantidadEmpleadoService = {
    GetCantidadEmpleados,
    GetUrlComplete
}