import { useEffect, useState } from "react";
import { toastService } from "../../../toast/toastService";
import {
  GetQuestionByIdAnswerDto,
  GetQuestionByIdDto,
} from "../../../../services/preguntas/Contracts";
import { QuestionService } from "../../../../services/preguntas/QuestionService";
import { useAppSelector } from "../../../../store/hooks";
import { currentQuestionSelectedSelector } from "../../../../store/slices/questions/currentQuestionSelectedSlice";
import { modalService } from "../../ModalService";
import EditOrDeleteQuestion from "./EditOrDeleteQuestion";
import MoreIcon from "../../../../../shared/IconSvg/More/MoreIcon";
import { messagesErrorToast } from "../../../../../shared/components/toast/messagesErrorToast";
import {
  DeleteAnswersRequest,
  SaveOrUpdateAnswerRequest,
} from "../../../../../shared/services/respuestas/Contracts";
import { AnswerService } from "../../../../../shared/services/respuestas/AnswerService";
import CreateQuestion from "./CreateQuestion";
import { connectionPendings } from "../../../../../shared/store/slices/loadingConnectionSlice";
import AnswerInputSkeleton from "./AnswerInputSkeleton";
import ModalMediumScreenTemplate from "../../modalTemplates/ModalMediumScreenTemplate";

const CONNECTION_USED : CONNECTION_USED_JSON = {
  primera: QuestionService.GetUrlComplete.GetQuestionById,
  segunda: AnswerService.GetUrlComplete.DeleteAnswerById,
  tercera: AnswerService.GetUrlComplete.SaveOrUpdateAnswer
}

interface CONNECTION_USED_JSON {
  [key: string]: any;
}

const QuestionEditModal = () => {
  const currentQuestionId = useAppSelector(currentQuestionSelectedSelector);
  const loadings = useAppSelector(connectionPendings);
  const [isPendingConnection, setIsPendingConnection] = useState<boolean>(false);

  //Estado que almacena todas las respuestas eliminadas
  const [removedAnswers, setRemovedAnswers] = useState<
    GetQuestionByIdAnswerDto[]
  >([]);

  //Estado que almacena todas las respuestas modificadas y nuevas
  const [allAnswer, setAllAnswer] = useState<GetQuestionByIdAnswerDto[]>([]);
  const [question, setQuestion] = useState<GetQuestionByIdDto | undefined>();

  const handleOnRetry = (value: boolean) => {
    modalService.closeModal("QuestionEditModal");
  };

  const handleSetPendingConnection = () =>{
    var awaitResults : boolean = false;

    var connections = Object.keys(CONNECTION_USED);

    for (let index = 0; index < connections.length; index++) {
      let key = connections[index];
      
      let connection = CONNECTION_USED[key];

      if(loadings.includes(connection)){
        awaitResults = true;
        break;
      }
    }

    setIsPendingConnection(awaitResults);
  }

  const HandleGetQuestion = async () => {
    if (currentQuestionId && currentQuestionId.id) {
      var response = await QuestionService.GetQuestionById(
        currentQuestionId.id
      );

      if (!response?.hasError && response?.data) {
        setQuestion(response.data);

        if (response.data.respuestas && response.data.respuestas.length > 0)
          setAllAnswer(response.data.respuestas.map((x) => x));
      }
    }
  };

  //Guarda las respuestas modificadas/creadas proveniente de los componentes hijos
  // : setea la variable allAnswers :
  //
  const handleModifyAnswer = (
    answersModifiqued: GetQuestionByIdAnswerDto[]
  ) => {
    setAllAnswer(answersModifiqued);
  };

  //Guarda las respuestas eliminadas proveniente del componente hijo EditOrDeleteQuestion
  // : setea la variable removedAnswers :
  //
  const handleRemoveAnswer = (answersRemoved: GetQuestionByIdAnswerDto[]) => {
    setRemovedAnswers(answersRemoved);
  };

  //02
  //Filtra aquellas respuestas que contienen el mismo contenido
  // : devuelve una lista de respuestas filtradas :
  //
  const handleRemoveIfNotModify = (
    answers: GetQuestionByIdAnswerDto[]
  ): GetQuestionByIdAnswerDto[] => {
    let result: GetQuestionByIdAnswerDto[] = [];

    result = answers.reduce(
      (acumulador: GetQuestionByIdAnswerDto[], current) => {
        var answeIsModifiqued: boolean =
          question?.respuestas.find((x) =>
            x.respuesta.toLowerCase() === current.respuesta.toLowerCase()
          ) == null;
        var percentajeIsModifiqued: boolean =
          question?.respuestas.find(
            (x) => x.porcentaje === current.porcentaje && x.id === current.id
          ) == null;

        if (answeIsModifiqued || percentajeIsModifiqued) {
          acumulador.push(current);
        }

        return acumulador;
      },
      []
    );

    return result;
  };

  //03
  //Guarda en base de datos
  const handleSaveOrUpdateAnswers = async (
    answerFiltered: GetQuestionByIdAnswerDto[]
  ) => {
    if (currentQuestionId && currentQuestionId.id) {
      var request: SaveOrUpdateAnswerRequest[] = answerFiltered.map(
        (element) => {
          var data: SaveOrUpdateAnswerRequest = {
            contenido: element.respuesta,
            id: element.id,
            porcentaje: element.porcentaje,
            preguntaId: currentQuestionId.id!,
          };

          return data;
        }
      );

      var result = await AnswerService.SaveOrUpdateAnswer(request);

      if (result && result.data) {
        toastService.success(messagesErrorToast.DATA_SAVED_SUCCESS);
      }
    } else {
      toastService.error(messagesErrorToast.DATA_NOT_FOUND);
    }
  };

  //03
  //Elimina en base de datos
  const handleDeleteAnswers = async (
    answerToRemove: GetQuestionByIdAnswerDto[]
  ) => {
    if (currentQuestionId && currentQuestionId.id) {
      var data: number[] = answerToRemove.map((element) => element.id);

      var request: DeleteAnswersRequest = {
        respuestaIds: data,
      };

      var result = await AnswerService.DeleteAnswerById(request);

      if (result && result.data) {
        toastService.success(messagesErrorToast.DATA_DELETED_SUCCESS);
      }
    } else {
      toastService.error(messagesErrorToast.DATA_NOT_FOUND);
    }
  };

  //01
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const answerFiltered = handleRemoveIfNotModify(allAnswer);

    if (answerFiltered.length === 0 && removedAnswers.length === 0) {
      toastService.warning("No hubieron modificaciones");
      return;
    }

    //Editar o crear
    if (answerFiltered.length > 0) {
      handleSaveOrUpdateAnswers(answerFiltered);
    }
    //Eliminar
    if (removedAnswers.length > 0) {
      handleDeleteAnswers(removedAnswers);
    }
  };

  useEffect(() => {
    HandleGetQuestion();
  }, [currentQuestionId]);

  useEffect(() =>{
    handleSetPendingConnection();
  }, [loadings])

  return (
    <ModalMediumScreenTemplate
      onClicked={(value) => handleOnRetry(value)}
      title={question?.pregunta ?? "Sin contenido"}
    >
      <form className="p-4 md:p-5" onSubmit={(e) => handleSubmit(e)}>
        <div className="grid gap-4 mb-4 grid-cols-2">
          <div className="col-span-2 text-center">
            <p className="mb-2 text-sm font-medium text-gray-900 dark:text-white">
              Respuestas
            </p>

            {loadings.includes(
              QuestionService.GetUrlComplete.GetQuestionById
            ) ? (
              <AnswerInputSkeleton />
            ) : (
              <EditOrDeleteQuestion
                allAnswer={allAnswer}
                removedAnswers={removedAnswers}
                onModifyAnswer={(value) => handleModifyAnswer(value)}
                onRemoveAnswer={(answer) => handleRemoveAnswer(answer)}
              />
            )}

            {!loadings.includes(
              QuestionService.GetUrlComplete.GetQuestionById
            ) && (
              <>
                <hr className="mb-3" />

                <p className="mb-2 text-sm font-medium text-gray-900 dark:text-white">
                  Agregar respuesta
                </p>

                <CreateQuestion
                  allAnswer={allAnswer}
                  onCreateAnswer={(value) => handleModifyAnswer(value)}
                />
              </>
            )}
          </div>
        </div>

        <div className="flex justify-between items-center">
          <span></span>
          <button
            type="submit"
            disabled={isPendingConnection}
            className="text-white flex items-center bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-2 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          >
            <MoreIcon />
            Guardar cambios
          </button>
        </div>
      </form>
    </ModalMediumScreenTemplate>
  );
};

export default QuestionEditModal;
