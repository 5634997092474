import ApiService from "../../shared/apiService/api";
import { setcurrentHome } from "../../shared/store/slices/home/currentHomeSlice";
import { store } from "../../shared/store/store";
import { BaseResponse } from "../../shared/wrappers/BaseResponse";
import { GetAllUserResponseContract } from "./Contracts";
const urlBase = "usuarios";
const endpoints = {
    GetAllUser: 'GetAllUsers'
}

const GetAllUser = async (page: number, pageSize: number) : Promise<BaseResponse<GetAllUserResponseContract[]> | undefined> =>{
    let finalUrl = urlBase+"/"+endpoints.GetAllUser;
    var response = await ApiService.get<GetAllUserResponseContract[]>(finalUrl, {page, pageSize})

    //Guardo estado
    console.log(response?.data, "que tinee");
    store.dispatch(setcurrentHome(response?.data))
    return response;
}

const GetUrlComplete = {
    GetAllUser: `${urlBase}/${endpoints.GetAllUser}`.toLowerCase()
}

export const HomeService = {
    GetAllUser,
    GetUrlComplete
}