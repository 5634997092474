import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import { GetAllCompanySoliContract } from "../../../../features/solicitudEmpresa/Contracts";

const initialState: GetAllCompanySoliContract[] = [{
    id: 0,
    email: "",
    nombre: "",
    telefono: "",
    cargo: "",
    cantidadEmpleados: "",
    tipoOrganizacion: "",
    rubro: ""
}];

export const soliCompanySlice = createSlice({
  name: "soliCompanySlice",
  initialState,
  reducers: {
    setsoliCompany: (state, action: PayloadAction<GetAllCompanySoliContract[] | undefined>) => {
        if(action.payload !== null && action.payload)
        {
            for (let index = 0; index < action.payload.length; index++) {
                const element = action.payload[index];
                if(state[0].id === 0){
                    state[0].id = element.id;
                    state[0].email = element.email;
                    state[0].nombre = element.nombre;
                    state[0].telefono = element.telefono;
                    state[0].cargo = element.cargo;
                    state[0].cantidadEmpleados = element.cantidadEmpleados;
                    state[0].tipoOrganizacion = element.tipoOrganizacion;
                    state[0].rubro = element.rubro;
                }else{
                    state.push(element)
                }
            }

        }
    }
  },
});
export const { setsoliCompany } = soliCompanySlice.actions;
// Other code such as selectors can use the imported `RootState` type
export const currentHomeSelector = (state: RootState) => state.currentHome;
export default soliCompanySlice.reducer;