import CachedIcon from "@mui/icons-material/Cached";
import { useEffect, useState } from "react";
import AccordionGeneric from "../../../../shared/components/accordeon/AccordeonGeneric";
import GearSvg, { teeth } from "../../../../shared/IconSvg/Gear/GearSvg";
import { EmpresaDientesGearResultResponse, GetResultsByCompanyIdResponseDto } from "../../../../shared/services/empresas/Contracts";
import { EmpresasService } from "../../../../shared/services/empresas/EmpresasService";
import { DientesGearResultResponse } from "../../../../shared/services/engranajesGenerales/Contracts";
import { useAppSelector } from "../../../../shared/store/hooks";
import { currentCompanySelectedSelector } from "../../../../shared/store/slices/company/currentCompanySelectedSlice";
import { connectionPendings } from "../../../../shared/store/slices/loadingConnectionSlice";
import { modalService } from "../ModalService";
import ModalFullScreenTemplate from "../modalTemplates/ModalFullScreenTemplate";

const CompanyResultModal = () => {
    const currentCompanySelected = useAppSelector(currentCompanySelectedSelector);
    const loadingConnection = useAppSelector(connectionPendings);
    const [getCompanyResultByUser, setCompanyResultByUser] = useState<
      GetResultsByCompanyIdResponseDto[] | undefined
    >();
  
    const handleGetCompanyResultByUser = async () => {

        console.log(currentCompanySelected, "currentCompanySelected");
        if(currentCompanySelected.id){
            var results = await EmpresasService.GetResultsByCompanyId(currentCompanySelected.id);
  
            setCompanyResultByUser(results?.data);
        }
    };
  
    const handleSetTeethAmount = (
      dientes:
        | DientesGearResultResponse[]
        | EmpresaDientesGearResultResponse[]
        | undefined
    ): teeth[] => {
      var teeths: teeth[] = [];
  
      if (dientes) {
        teeths = dientes.map((diente, index) => {
          var teeth: teeth = {
            diente: index + 1,
            porcentaje: diente.porcentaje,
          };
  
          return teeth;
        });
      }
  
      return teeths;
    };
  
    useEffect(() => {
      handleGetCompanyResultByUser();
    }, []);

    const handleOnRetry = (value: boolean) =>{
        modalService.closeModal("CompanyResultModal");
    }

      return(

          <ModalFullScreenTemplate
    onClicked={(value) => handleOnRetry(value)}
    title={currentCompanySelected.name??""} >
        <div className="p-4">
            {
                !loadingConnection.includes(
                    EmpresasService.GetUrlComplete.GetResultsByCompanyId
                  ) ? (
                    <>
                      <h3>
                        Resultados globales
                        <p
                          onClick={() => handleGetCompanyResultByUser()}
                          className="ml-1 inline cursor-pointer"
                          title="Recargar"
                        >
                          <CachedIcon />
                        </p>
                      </h3>
                
                      {getCompanyResultByUser?.map((engranaje) => (
                        <div key={engranaje.id}>
                          <AccordionGeneric
                            key={engranaje.id}
                            withoutIcon
                            title={
                              <h1 className="uppercase font-bold text-white">
                                {engranaje.nombre}
                              </h1>
                            }
                            className="!bg-page"
                          >
                            <div className="flex rounded-b-lg justify-between py-4">
                              <div className="rounded-b-lg pl-4 w-[40rem] h-min">
                                {engranaje?.dientes?.map((diente) => (
                                  <div
                                    key={diente.nombre}
                                    className="flex justify-between item-center border-b p-2"
                                  >
                                    <p>{diente.nombre}</p>
                
                                    <p className="font-bold">{diente.porcentaje}%</p>
                                  </div>
                                ))}
                              </div>
                
                              <div className="m-auto">
                                <div className="flex flex-col items-center">
                                  <GearSvg
                                    className="w-[20rem] h-[20rem] animate-spin-wobble"
                                    teethAmount={handleSetTeethAmount(engranaje.dientes)}
                                    text={engranaje.nombre}
                                    textClassName="font-bold"
                                  />
                
                                  <p className="text-[#2596aa] text-2xl font-bold">
                                    {engranaje?.porcentaje ?? 0}%
                                  </p>
                                </div>
                              </div>
                            </div>
                          </AccordionGeneric>
                        </div>
                      ))}
                
                      {getCompanyResultByUser && (
                        <div>
                          <h1 className="uppercase text-md font-bold mt-10 text-center">
                            Representación grafica de tu empresa
                          </h1>
                          <div className="flex justify-center mt-20">
                            <div className="relative">
                              <GearSvg
                                className="w-[16rem] h-[16rem] relative left-[7rem] bottom-[2rem] rotate-[21deg] z-10 animate-spin-wobble"
                                teethAmount={handleSetTeethAmount(
                                  getCompanyResultByUser![2].dientes
                                )}
                              />
                              <span className="absolute top-[5rem] left-[12.5rem] z-50 text-white">
                                Liderazgo
                              </span>
                            </div>
                
                            <div className="relative">
                              <GearSvg
                                className="w-[10rem] h-[10rem] relative top-[8rem] z-20 animate-spin-wobble"
                                teethAmount={handleSetTeethAmount(
                                  getCompanyResultByUser![1].dientes
                                )}
                              />
                              <span className="absolute left-[3rem] bottom-[6.3rem] z-50 text-white">
                                Bienestar
                              </span>
                            </div>
                
                            <div className="relative">
                              <GearSvg
                                className="w-[20rem] h-[20rem] relative right-[8rem] bottom-[5rem] z-30 animate-spin-wobble"
                                teethAmount={handleSetTeethAmount(
                                  getCompanyResultByUser![0].dientes
                                )}
                              />
                              <span className="absolute z-50 top-[4rem] left-[-1rem] text-white">
                                Organización
                              </span>
                            </div>
                          </div>
                        </div>
                      )}
                    </>
                  )

                  : (
                    <>
                      <ul className="space-y-1.5 animate-pulse mt-10">
                        <div className="bg-gray-200 rounded w-[80%] h-20 p-3 m-auto mb-4"></div>
                        <div className="bg-gray-200 rounded w-[80%] h-20 p-3 m-auto !mb-4"></div>
                        <div className="bg-gray-200 rounded w-[80%] h-20 p-3 m-auto mb-4"></div>
                      </ul>
                    </>
                  )
                }
        </div>
    </ModalFullScreenTemplate>
    
    )
};

export default CompanyResultModal;