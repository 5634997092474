import { useEffect, useState } from "react";
import TableResponsive, { TableOnActionProps } from "../../shared/components/tables/TableResponsive";
import { GetAllCompanyResponseDto } from "../../shared/services/empresas/Contracts";
import { EmpresasService } from "../../shared/services/empresas/EmpresasService";
import { useAppDispatch, useAppSelector } from "../../shared/store/hooks";
import { connectionPendings } from "../../shared/store/slices/loadingConnectionSlice";
import { modalService } from "../../shared/components/modal/ModalService";
import { SetCompanySelected } from "../../shared/store/slices/company/currentCompanySelectedSlice";
import VisibilityIcon from '@mui/icons-material/Visibility';
import DynamicFeedIcon from '@mui/icons-material/DynamicFeed';

const DEFAULT_PAGE = 1;
const DEFAULT_PAGE_SIZE = 10;

const BUTTONS = {
    verresultados: <VisibilityIcon/>,
    observaciones: <DynamicFeedIcon/>
}

const BUTTON_RETURN = {
    verresultados: "id",
    observaciones: "id"
}

const BUTTON_SELECT = {
    verresultados: "verresultados",
    observaciones: "observaciones"
}

const EXCLUDED_COLUMNS = ["id", "nivelauditoriaid"]

const MODIFY_COLUMN = {
    nombreowner: "Nombre Cliente",
    apellidoowner: "Apellido Cliente",
    cantidadempleados: "Cantidad Empleados",
    tipoorganizacion: "Tipo De Organización"
}

const Empresas = () =>{
    const loadingConnections = useAppSelector(connectionPendings);
    const [companies, setCompanies] = useState<GetAllCompanyResponseDto[]>();
    const dispatch = useAppDispatch();

    const handleGetCompanies = async (
        page: number = DEFAULT_PAGE,
        pageSize: number = DEFAULT_PAGE_SIZE
    ) =>{
        var result = await EmpresasService.GetAllCompanies(page, pageSize);

        if(result && !result.hasError){
            setCompanies(result.data);
        }
    }

    const handleActionButton = (value: TableOnActionProps) =>{
        if(value.button === BUTTON_SELECT.verresultados){
            let name = companies?.find(x => x.id === value.value)?.nombre??"";
            dispatch(SetCompanySelected({id: Number(value.value), name}));
            console.log(value.value, "valor");
            modalService.CompanyResult();
        }

        if(value.button === BUTTON_SELECT.observaciones){
            let name = companies?.find(x => x.id === value.value)?.nombre??"";
            dispatch(SetCompanySelected({id: Number(value.value), name}));
            modalService.TeethObservation();
        }
    }

    useEffect(() =>{
        handleGetCompanies();
    },[])

    return(
        <div className="p-10">
        <h1 className="text-2xl mb-4 font-bold">
          Grilla empresas
        </h1>

        <div className="">
          <TableResponsive
            values={companies}
            actions={BUTTONS}
            actionReturn={BUTTON_RETURN}
            onAction={(value) => handleActionButton(value)}
            removeColumn={EXCLUDED_COLUMNS}
            modifyColumn={MODIFY_COLUMN}
            isLoading={loadingConnections.includes(EmpresasService.GetUrlComplete.GetAllCompanies)}
          />
        </div>
      </div>
    )
}


export default Empresas;