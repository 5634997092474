import { Pagination } from "@mui/material";
import { useEffect, useState } from "react";
import SimpleLoading from "../../../shared/IconSvg/Spinner/SimpleLoading";

const TableResponsive = ({
  values,
  modifyColumn,
  removeColumn,
  orderColumn,
  actions,
  actionReturn,
  onAction,
  pagination,
  onPage,
  isLoading,
}: TableResponsiveProps) => {
  const [columns, setColumns] = useState<TableResponsiveColumnProps[]>();
  const [currentPage, setCurrentPage] = useState<number>();
  const [totalPages, setTotalPages] = useState<number>();
  const [loading, setLoading] = useState<boolean>(false);

  const handleSetColumn = () => {
    if (values && values?.length > 0) {
      var order = 1;

      var column = Object.keys(values[0]).map((key) => {
        //Validacion para no mostrar campos con listas
        if (Array.isArray(values[0][key])) {
          let col: TableResponsiveColumnProps = {
            name: "array",
            realName: "array",
            show: false,
            order: 999,
          };

          return col;
        }

        var currentKey = key.toLowerCase();
        //Todas las keys de la orderColumn
        var columnsToOrderKey = orderColumn
          ? Object.keys(orderColumn)
          : undefined;

        //Valor de todas las keys de la orderColumn
        var columnToOrderValue: number[] | undefined = columnsToOrderKey
          ? columnsToOrderKey.map((x) => orderColumn![x])
          : undefined;

        var name = modifyColumn ? modifyColumn[currentKey] : undefined;
        var show = removeColumn ? !removeColumn.includes(currentKey) : true;
        var orderModify = 0;

        if (columnsToOrderKey && orderColumn) {
          if (
            !columnsToOrderKey.includes(currentKey) &&
            columnToOrderValue?.includes(order) &&
            show
          ) {
            orderModify = 100 + order;
          } else if (columnsToOrderKey.includes(currentKey)) {
            orderModify = orderColumn[currentKey];
          }
        }

        var col: TableResponsiveColumnProps = {
          name: name ? name : key,
          realName: key,
          show,
          order: orderModify === 0 ? (show ? order : 0) : orderModify,
        };

        //Sumamos solo si esta columna se mostrara
        order += show ? 1 : 0;

        return col;
      });

      column.sort((a, b) => a.order - b.order);

      setColumns(column);
    }
  };

  const handleSetPagination = () => {
    if (pagination) {
      setCurrentPage(pagination.currentPage);
      setTotalPages(pagination.totalPages);
    }
  };

  useEffect(() => {
    handleSetColumn();
    handleSetPagination();
    console.log(pagination, "xzdd");
  }, [values, pagination]);

  useEffect(() => {
    if (isLoading !== undefined) {
      setLoading(isLoading);
    }
  }, [isLoading]);

  return (
    <>
      <div className="overflow-x-auto shadow-md sm:rounded-lg">
        {loading && (
          <div className="absolute top-0 left-0 bg-[#80808040] w-full h-full flex justify-center items-center">
            <SimpleLoading />
          </div>
        )}
        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
          <thead className="text-xs bg-gray-800 text-white top-[20px]">
            <tr className="uppercase">
              {columns?.map(
                (column) =>
                  column.show && (
                    <th key={column.realName} scope="col" className="px-6 py-3">
                      {column.name}
                    </th>
                  )
              )}
              {!values || values.length === 0 ? (
                <th scope="col" className="px-6 py-3">
                  No hay contenido
                </th>
              ) : (
                actions && (
                  <th scope="col" className="px-6 py-3">
                    Acciones
                  </th>
                )
              )}
            </tr>
          </thead>
          <tbody>
            {values?.map((value, index) => (
              <tr
                key={index}
                className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700"
              >
                {columns?.map(
                  (column, index) =>
                    column.show && (
                      <td key={index} className="px-6 py-4">
                        {value[column.realName]}
                      </td>
                    )
                )}
                {actions && (
                  <td className="px-6 py-4">
                    <div className="flex">
                      {Object.keys(actions).map((key) => (
                        <div
                          key={key}
                          className="cursor-pointer p-1 text-black"
                          title={key.toUpperCase()}
                          onClick={() => {
                            if (actionReturn) {
                              //OBtengo todas las keys de la grilla
                              var keys = Object.keys(value);
                              var keyToReturn = keys.find((x) =>
                                x.toLowerCase().includes(actionReturn[key])
                              );
                              var valueKey = keyToReturn && value[keyToReturn];

                              //Devuelvo valor solo si este tiene
                              if (valueKey)
                                onAction &&
                                  onAction({ value: valueKey, button: key });
                            }
                          }}
                        >
                          {/* Mostramos icono o lo que sea */}
                          {actions[key]}
                        </div>
                      ))}
                    </div>
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="mt-5 float-right">
        {pagination && onPage && (
          <div>
            <Pagination
              count={totalPages}
              page={currentPage}
              onChange={(e, page) => onPage(page)}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default TableResponsive;

export interface TableResponsiveColumnProps {
  name: string;
  realName: string;
  show: boolean;
  order: number;
}

export interface TableResponsiveValuesProps {
  value: { [key: string]: any }[];
}

export interface TableResponsivleActionProps {
  columnName: string;
}

export interface TableOnActionProps {
  value: any;
  button: string;
}

export interface TablePaginationProps {
  currentPage: number;
  totalPages: number;
  totalRecords: number;
}

export interface TableResponsiveProps {
  values: { [key: string]: any }[] | undefined;
  modifyColumn?: { [key: string]: any };
  removeColumn?: string[];
  orderColumn?: { [key: string]: number };
  actions?: { [key: string]: React.ReactNode };
  actionReturn?: { [key: string]: string }; // Retorna el valor de una key especificada
  onAction?: (value: TableOnActionProps) => void;
  pagination?: TablePaginationProps;
  onPage?: (value: number) => void;
  isLoading?: boolean;
}
