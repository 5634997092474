import ApiService from "../../apiService/api";
import { BaseResponse } from "../../wrappers/BaseResponse";
import { GetDienteObservacionResponseDto } from "./Contracts";

const urlBase = "dienteobservacion";
const endpoints = {
    GetDienteObservacion: 'GetDienteObservacion'
}

const GetDienteObservacion = async (companyId: number, teethId: number) : Promise<BaseResponse<GetDienteObservacionResponseDto[]>| undefined> =>{
    let finalUrl = urlBase+"/"+endpoints.GetDienteObservacion;
    var response = await ApiService.get<GetDienteObservacionResponseDto[]>(finalUrl, {companyId, teethId});
    return response;
}

const GetUrlComplete = {
    GetDienteObservacion: `${urlBase}/${endpoints.GetDienteObservacion}`.toLowerCase()
}

export const TeethObservationService = {
    GetDienteObservacion,
    GetUrlComplete
}