import * as Yup from 'yup';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { GetEmpresaInfoContractResponse } from '../../shared/services/cantidadEmpleados/Contract';
import { useState, useEffect } from 'react';
import { OrganizationTypeService } from '../../shared/services/organizacionTipo/OrganizationTypeService';
import { CantidadEmpleadoService } from '../../shared/services/cantidadEmpleados/CantidadEmpleadoService';
import { SolicitudEmpresaRequestDto, SolicitudUsuarioRequestDto } from '../../shared/services/solicitudEmpresa/Contract';
import { SolicitudEmpresaService } from '../../shared/services/solicitudEmpresa/SolicitudEmpresaService';
import { toastService } from '../../shared/components/toast/toastService';
import Aside from '../../shared/layout/Aside';
import Header from '../../shared/layout/header/Header';
import { EmpresasService } from '../../shared/services/empresas/EmpresasService';

const validationSchema = Yup.object({
    companyName: Yup.string().required('Nombre de empresa es requerido'),
    userName: Yup.string().required('Nombre Cliente es requerido'),
    lastName: Yup.string().required('Apellido Cliente es requerido'),
    email: Yup.string().email('Email inválido').required('Email es requerido'),
    phone: Yup.string().required('Teléfono es requerido'),
    password: Yup.string().required('Contraseña es requerida'),
    employeesCant: Yup.string().required("La cantidad de empleados es requerida."),
    organizationType: Yup.string().required("El tipo de organizacion es requerida."),
});

const NewUserCompanyForm = ({ companyInfo, organizationType }: NewUserCompanyFormProps) => {

    const [empresaData, setEmpresaData] = useState<GetEmpresaInfoContractResponse[] | undefined>([]);
    const [tipoOrganizacion, setTipoOrganizacion] = useState<GetEmpresaInfoContractResponse[] | undefined>([]);

    useEffect(() => {
        getTipoOrganizacion();
        getCantidadEmpleados();
    }, []);

    const getTipoOrganizacion = async () => {
        const response = await OrganizationTypeService.GetTipoOrganizacion();
        if (response?.data) {
            setTipoOrganizacion(response?.data);
        }
    };

    const getCantidadEmpleados = async () => {
        const response = await CantidadEmpleadoService.GetCantidadEmpleados();
        if (response?.data) {
            setEmpresaData(response?.data);
        }
    };

    const handleSubmit = async (values: NewUserCompanyFormValues, { resetForm, setSubmitting }: any) => {
        console.log('Submitting form...');  // Añadido para depuración
        const jefe: SolicitudUsuarioRequestDto = {
            Nombre: values.userName,
            Email: values.email,
            Password: values.password,
            Apellido: values.lastName,
            Telefono: values.phone,
        };

        const data: SolicitudEmpresaRequestDto = {
            Nombre: values.companyName,
            CantidadEmpleadosId: Number(values.employeesCant),
            TipoOrganizacionId: Number(values.organizationType),
            Jefe: jefe
        };        
        
        var result = await EmpresasService.Create(data);
        if(result && !result.hasError){
            toastService.success("Se ha creado el usuario CLIENTE correctamente.");
            resetForm();
        }

        setSubmitting(false); 
    };

    const initialValues: NewUserCompanyFormValues = {
        companyName: '',
        userName: '',
        lastName: '',
        email: '',
        password: '',
        phone: '',
        employeesCant: '',
        organizationType: '',
    };

    return (
        <div className='bg-gray-100 font-family-karla flex'>
            {/* <Aside /> */}

            <div className="w-full flex flex-col">
                {/* <Header /> */}

                <div className='flex justify-center mt-12'>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmit}
                    >
                        {({ isSubmitting, errors, touched }) => (
                            <Form className="w-full max-w-md px-8 bg-white rounded pt-10">
                                
                                <div className="mb-[22px]">
                                    <Field
                                        type="text"
                                        name="companyName"
                                        placeholder="Nombre de empresa"
                                        className="w-full px-5 py-3 text-base transition bg-transparent border rounded-md outline-none border-stroke dark:border-dark-3 text-body-color dark:text-dark-6 placeholder:text-dark-6 focus:border-primary dark:focus:border-primary focus-visible:shadow-none"
                                    />
                                    <ErrorMessage name="companyName" component="div" className="text-red-500 text-sm mt-1" />
                                </div>

                                <div className="mb-[22px]">
                                    <Field
                                        as="select"
                                        name="employeesCant"
                                        className="w-full px-5 py-3 text-base transition bg-transparent border rounded-md outline-none border-stroke dark:border-dark-3 text-body-color dark:text-dark-6 placeholder:text-dark-6 focus:border-primary dark:focus:border-primary focus-visible:shadow-none"
                                    >
                                        <option value="" disabled style={{ color: '#aaa' }}>
                                            Cantidad de empleados
                                        </option>
                                        {empresaData?.map((item, index) => (
                                            <option key={index} value={item.id}>
                                                {item.descripcion}
                                            </option>
                                        ))}
                                    </Field>
                                    <ErrorMessage name="employeesCant" component="div" className="text-red-500 text-sm mt-1" />
                                </div>

                                <div className="mb-[22px]">
                                    <Field
                                        as="select"
                                        name="organizationType"
                                        className="w-full px-5 py-3 text-base transition bg-transparent border rounded-md outline-none border-stroke dark:border-dark-3 text-body-color dark:text-dark-6 placeholder:text-dark-6 focus:border-primary dark:focus:border-primary focus-visible:shadow-none"
                                    >
                                        <option value="" disabled style={{ color: '#aaa' }}>
                                            Tipo de organización
                                        </option>
                                        {tipoOrganizacion?.map((item, index) => (
                                            <option key={index} value={item.id}>
                                                {item.descripcion}
                                            </option>
                                        ))}
                                    </Field>
                                    <ErrorMessage name="organizationType" component="div" className="text-red-500 text-sm mt-1" />
                                </div>

                                <div className="mb-[22px]">
                                    <Field
                                        type="text"
                                        name="userName"
                                        placeholder="Nombre Cliente"
                                        className="w-full px-5 py-3 text-base transition bg-transparent border rounded-md outline-none border-stroke dark:border-dark-3 text-body-color dark:text-dark-6 placeholder:text-dark-6 focus:border-primary dark:focus:border-primary focus-visible:shadow-none"
                                    />
                                    <ErrorMessage name="userName" component="div" className="text-red-500 text-sm mt-1" />
                                </div>

                                <div className="mb-[22px]">
                                    <Field
                                        type="text"
                                        name="lastName"
                                        placeholder="Apellido Cliente"
                                        className="w-full px-5 py-3 text-base transition bg-transparent border rounded-md outline-none border-stroke dark:border-dark-3 text-body-color dark:text-dark-6 placeholder:text-dark-6 focus:border-primary dark:focus:border-primary focus-visible:shadow-none"
                                    />
                                    <ErrorMessage name="lastName" component="div" className="text-red-500 text-sm mt-1" />
                                </div>

                                <div className="mb-[22px]">
                                    <Field
                                        type="email"
                                        name="email"
                                        placeholder="Email"
                                        className="w-full px-5 py-3 text-base transition bg-transparent border rounded-md outline-none border-stroke dark:border-dark-3 text-body-color dark:text-dark-6 placeholder:text-dark-6 focus:border-primary dark:focus:border-primary focus-visible:shadow-none"
                                    />
                                    <ErrorMessage name="email" component="div" className="text-red-500 text-sm mt-1" />
                                </div>

                                <div className="mb-[22px]">
                                    <Field
                                        type="text"
                                        name="password"
                                        placeholder="Contraseña Cuenta Cliente"
                                        className="w-full px-5 py-3 text-base transition bg-transparent border rounded-md outline-none border-stroke dark:border-dark-3 text-body-color dark:text-dark-6 placeholder:text-dark-6 focus:border-primary dark:focus:border-primary focus-visible:shadow-none"
                                    />
                                    <ErrorMessage name="password" component="div" className="text-red-500 text-sm mt-1" />
                                </div>

                                <div className="mb-[22px]">
                                    <Field
                                        type="text"
                                        name="phone"
                                        placeholder="Teléfono"
                                        className="w-full px-5 py-3 text-base transition bg-transparent border rounded-md outline-none border-stroke dark:border-dark-3 text-body-color dark:text-dark-6 placeholder:text-dark-6 focus:border-primary dark:focus:border-primary focus-visible:shadow-none"
                                    />
                                    <ErrorMessage name="phone" component="div" className="text-red-500 text-sm mt-1" />
                                </div>

                                <div className="mb-9">
                                    <button
                                        type="submit"
                                        className="w-full px-5 py-3 text-base text-white transition duration-300 ease-in-out border rounded-md cursor-pointer border-primary bg-primary hover:bg-blue-dark"
                                    >
                                        Crear Nuevo Usuario Cliente
                                    </button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        </div>
    )
}

export default NewUserCompanyForm;

export interface NewUserCompanyFormValues {
    companyName: string;
    userName: string;
    lastName: string;
    email: string;
    phone: string;
    password: string;
    employeesCant: string;
    organizationType: string;
}

interface NewUserCompanyFormProps {
    companyInfo?: GetEmpresaInfoContractResponse[];
    organizationType?: GetEmpresaInfoContractResponse[];
}
