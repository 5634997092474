import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../store";

// Define the initial state using that type
interface props{
    id: number | undefined
}
const initialState: props = {
    id: undefined
};
export const currentTeethSelectedSlice = createSlice({
  name: "currentTeethSelected",
  initialState,
  reducers: {
    SetTeethSelected: (state, action: PayloadAction<props | undefined>) => {
        if(action.payload !== null && action.payload){
            state.id = action.payload.id;    
        }
    }
  },
});
export const { SetTeethSelected } = currentTeethSelectedSlice.actions;
// Other code such as selectors can use the imported `RootState` type
export const currentTeethSelectedSelector = (state: RootState) => state.currentTeethSelected;
export default currentTeethSelectedSlice.reducer;