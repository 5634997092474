import { ReactNode, useEffect, useState } from 'react';
import { teethConfig } from './GearSvgConfigs';



const GearSvg = (props:props) => {
  
  const HandleHasBrokenGear = () =>{
    var existGearBroken = props.teethAmount.find(x => x.porcentaje <= 89);

    if(existGearBroken)
      return true;
    else{
      if(props.teethAmount.length === 0)
        return true;
      
      return false;

    }
  }

  const [teeth, setTeeth] = useState<teethProps[]>([]);
  const [gearBaseColor, setGearBaseColor] = useState<string>();

  const hasBrokenGear = HandleHasBrokenGear();

  const randomNumber = Math.random();

  const maxSizeOfTeeth = 8;

  const SetColorBase = () => {
    var hasBrokenTeeth = props.teethAmount.find(x => x.porcentaje < 75);
    if (hasBrokenTeeth){
      setGearBaseColor(
        teethConfig.teethColorGama.base1
      )
    } 
    else{
      setGearBaseColor(
        teethConfig.teethColorGama.base2
      )
    }
  } 

  const getTeeth = () => {
    
    const teeths = props.teethAmount.length;

    var path : teethProps[] = 
    [...Array(teeths)].map((_, index) => {
      const currentTeeth = props.teethAmount.find((x) => x.diente === index + 1);
      const sizeOfTeeth = Math.round((currentTeeth!.porcentaje * maxSizeOfTeeth) / 100);
      //const colors = generateColorArray(currentTeeth.porcentaje);
      const color = !hasBrokenGear 
      ? 
      teethConfig.teethColorSet.find(x => 
          currentTeeth!.porcentaje >= x.from && currentTeeth!.porcentaje <= x.to)?.color??"white"
      :
      teethConfig.teethColorGama.base1;
      console.log(color, "color");
      const angle = (index * 360) / teeths - 90;
      const x1 = 100 + 65 * Math.cos((angle * Math.PI) / 180);
      const y1 = 100 + 65 * Math.sin((angle * Math.PI) / 180);
      const x2 = 100 + 65 * Math.cos(((angle - (25 + 0)) * Math.PI) / 180);
      const y2 = 100 + 65 * Math.sin(((angle - (25 + 0)) * Math.PI) / 180);
      const x3 = 100 + (74 + sizeOfTeeth) * Math.cos(((angle - 15) * Math.PI) / 180);
      const y3 = 100 + (74 + sizeOfTeeth) * Math.sin(((angle - 15) * Math.PI) / 180);
      const x4 = 100 + (74 + sizeOfTeeth) * Math.cos(((angle - 5) * Math.PI) / 180);
      const y4 = 100 + (74 + sizeOfTeeth) * Math.sin(((angle - 5) * Math.PI) / 180);

      var teethCfg : teethProps= {
        d: `M ${x1} ${y1} L ${x2} ${y2} L ${x3} ${y3} L ${x4} ${y4} Z`,
        fill: color,
        stroke: "black",
        strokeWidth: "1",
      } 
      return teethCfg;
    });

    setTeeth(path);
  };

  useEffect(() => {
    getTeeth();
    SetColorBase();
  }, []);
  return (
    <>
      <svg
        viewBox="0 0 200 200"
        xmlns="http://www.w3.org/2000/svg"
        className={props.className}
      >
        {/* Configuracion de colores en dientes */}
       <defs>
         {teethConfig.teethColorSetting.map(config => (
          <linearGradient key={Math.random()} id={config.color+randomNumber.toString()}>
            <stop offset={config.offset} stopColor={config.stopColor}/>
            <stop offset={config.offset2} stopColor={config.stopColor2}/>
          </linearGradient>
         ))}

        
        </defs>


        {/* Dientes del engranaje */}
        {teeth.map(element => (
          
          <path 
          d={element.d} 
          fill={`url(#${element.fill+randomNumber.toString()})`}
          stroke={element.stroke} 
          strokeWidth={element.strokeWidth} 
          strokeLinejoin="round"/>
        ))}
        {/* Decoración */}

        <circle cx="100" cy="100" r="65" stroke='black' strokeWidth={.2} fill={`url(#${gearBaseColor+randomNumber.toString()})`}/>

        <circle cx="100" cy="100" r="50" fill={`url(#${gearBaseColor+randomNumber.toString()})`}/>
        <circle cx="100" cy="100" r="35" fill={`url(#${gearBaseColor+randomNumber.toString()})`}/>

        <circle cx="100" cy="100" r="25" fill={`url(#${gearBaseColor+randomNumber.toString()})`}/>


        

      </svg>
      {props.text &&
      <div className={`${props.className} absolute !animate-none top-[46%] flex justify-center`}>
        <span className={`${props.textClassName} ${hasBrokenGear ? 'text-white' : 'text-black' }`}>{props.text}</span>
        </div>
      }

    </>
  );
};

export default GearSvg;

interface props {
    teethAmount: teeth[];
    className?: string;
    textClassName?: string;
    text?: string;
}

export interface teeth{
    diente: number;
    porcentaje: number;
}


interface teethProps{
  d: string;
  fill: string;
  stroke: string;
  strokeWidth: string;
}