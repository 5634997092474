import { useEffect, useState } from "react";
import { GetQuestionByIdAnswerDto } from "../../../../services/preguntas/Contracts";
import DeleteIcon from "@mui/icons-material/Delete";
import { toastService } from "../../../../../shared/components/toast/toastService";

const ANSWER_PERCENTAJE = [0, 25, 50, 75, 100];
const ERROR_PERCENTAJE_EXIST = "Ya se ha cargado una respuesta con ese mismo porcentaje";
const EditOrDeleteQuestion = ({
  allAnswer,
  removedAnswers,
  onModifyAnswer,
  onRemoveAnswer
}: AnswersComponentProps) => {

  const [allAnswerModifiqued, setAllAnswerModifiqued] = useState<GetQuestionByIdAnswerDto[]>(allAnswer);

  
  //Modifica una respuesta
  // Variable que almacena las respuestas modificadas y nuevas
  // : allAnswerModifiqued :
  //
  const handleChangeAnswer = (answer: GetQuestionByIdAnswerDto, newAnswerr: string ) => {
    if (allAnswerModifiqued) {
      var newAnswers = allAnswerModifiqued?.map((x) => {
        var newAnswer: GetQuestionByIdAnswerDto = {
          id: x.id,
          respuesta: x.respuesta,
          porcentaje: x.porcentaje,
        };

        console.log(newAnswer, "new answer")
        console.log(answer)
        if (newAnswer.id === answer.id && newAnswer.porcentaje === answer.porcentaje) {

          newAnswer.respuesta = newAnswerr;
        }

        return newAnswer;
      });

      setAllAnswerModifiqued(newAnswers);
    }
  };

  //Modifica el porcentaje de una respuesta
  // Variable que almacena las respuestas modificadas y nuevas
  // : allAnswerModifiqued :
  //
  const handleChangePercentaje = (
    answerId: number,
    percentaje: string,
    answer: string
  ) => {
    if (percentaje) {
      if (allAnswerModifiqued) {
        var porcentajeExist = allAnswerModifiqued.find(x => x.porcentaje === Number(percentaje));

        if(porcentajeExist){
          toastService.error(ERROR_PERCENTAJE_EXIST)
          return;
        }
        var newAnswers = allAnswerModifiqued?.map((x) => {
          var newAnswer: GetQuestionByIdAnswerDto = {
            id: x.id,
            respuesta: x.respuesta,
            porcentaje: x.porcentaje,
          };

          if (newAnswer.id === answerId && newAnswer.respuesta === answer) {
            newAnswer.porcentaje = Number(percentaje);
          }

          return newAnswer;
        });

        setAllAnswerModifiqued(newAnswers);
      }
    }
  };

    //Elimina una respuesta
  // Variable que almacena las respuestas eliminadas
  // : removedAnswers :
  //
  const handleRemoveAnswer = (answer: GetQuestionByIdAnswerDto) => {
    const answerId = answer.id;
    const answerToRemove = allAnswerModifiqued.find(
      (x) => x.id === answerId && x.respuesta.includes(answer.respuesta)
    );

    if (answerToRemove) {
      var answerFiltered = allAnswerModifiqued.filter((x) => x.id !== answerId);

      setAllAnswerModifiqued(answerFiltered);

      //Si es != 0 entonces es una respuesta que ya esta en la base de datos
      if (answerId !== 0) {
        if (removedAnswers.length === 0) {
          onRemoveAnswer([answerToRemove]);
        } else {
          onRemoveAnswer([...removedAnswers, answerToRemove]);
        }
      }
    }
  };
  
  useEffect(() =>{
    if(allAnswerModifiqued && allAnswerModifiqued.length > 0)
      onModifyAnswer(allAnswerModifiqued)
  },[allAnswerModifiqued])

  useEffect(() =>{
    setAllAnswerModifiqued(allAnswer);
  }, [allAnswer])

  return (
    <>
      {allAnswerModifiqued?.map((answer, index) => (
        <div key={index} className="text-center">
          <input
            title={answer.respuesta}
            type="text"
            className="inline w-[50%] sm:w-[80%] mr-3 bg-gray-50 border mb-3 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
            onChange={(e) => handleChangeAnswer(answer, e.target.value)}
            value={answer.respuesta}
          />
          <select
            onChange={(e) => handleChangePercentaje(answer.id, e.target.value, answer.respuesta)}
            value={answer.porcentaje}
            className="bg-gray-50 border w-[5rem] border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 p-2.5 inline"
          >
            {ANSWER_PERCENTAJE.map((x, index) => (
              <option selected={answer.porcentaje === x} key={index} value={x}>
                {x}
              </option>
            ))}
          </select>
          <div
            className="inline ml-3 cursor-pointer"
            title="Eliminar respuesta"
            onClick={() => handleRemoveAnswer(answer)}
          >
            <DeleteIcon />
          </div>
        </div>
      ))}
    </>
  );
};

interface AnswersComponentProps {
  allAnswer: GetQuestionByIdAnswerDto[];
  removedAnswers: GetQuestionByIdAnswerDto[];
  onModifyAnswer: (answers: GetQuestionByIdAnswerDto[]) => void;
  onRemoveAnswer: (answer: GetQuestionByIdAnswerDto[]) => void;
}

export default EditOrDeleteQuestion;
