import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../store";

import { GearContract } from "../../../../features/listaEngranajes/Contract"
// Define the initial state using that type
const initialState: GearContract[] = [{
    id: 0,
    name: "",
    dientes: []
}];
export const gearAllSlice = createSlice({
  name: "gearAll",
  initialState,
  reducers: {
    setAllGears: (state, action: PayloadAction<GearContract[] | undefined>) => {
        if(action.payload !== null && action.payload)
        {
            for (let index = 0; index < action.payload.length; index++) {
                const element = action.payload[index];
                if(state[0].id === 0){
                    state[0].dientes = element.dientes;
                    state[0].id = element.id;
                    state[0].name = element.name;
                }else{
                    state.push(element)
                }
            }

            state = state.filter(x => x.id !== 0);
        }
    }
  },
});
export const { setAllGears } = gearAllSlice.actions;
// Other code such as selectors can use the imported `RootState` type
export const gearlAll = (state: RootState) => state.allGear;
export default gearAllSlice.reducer;