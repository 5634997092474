import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import { GetAllUserResponseContract } from "../../../../features/home/Contracts";
// Define the initial state using that type

const initialState: GetAllUserResponseContract[] = [{
    id: 0,
    nombre: "string",
    apellido: "",
    email: "",
    telefono: 0
}];

export const currentHomeSlice = createSlice({
  name: "currentHome",
  initialState,
  reducers: {
    setcurrentHome: (state, action: PayloadAction<GetAllUserResponseContract[] | undefined>) => {
        if(action.payload !== null && action.payload)
        {
            for (let index = 0; index < action.payload.length; index++) {
                const element = action.payload[index];
                if(state[0].id === 0){
                    state[0].id = element.id;
                    state[0].nombre = element.nombre;
                    state[0].apellido = element.apellido;

                    state[0].email = element.email;

                    state[0].telefono = element.telefono;


                }else{
                    state.push(element)
                }
            }

        }
    }
  },
});
export const { setcurrentHome } = currentHomeSlice.actions;
// Other code such as selectors can use the imported `RootState` type
export const currentHomeSelector = (state: RootState) => state.currentHome;
export default currentHomeSlice.reducer;