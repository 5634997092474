import { Route, Routes } from "react-router-dom";
import Home from "../../features/home/Home";
import Login from "../../features/login/Login";
import NewUserCompanyForm from "../../features/newUserCompanyForm/NewUserCompanyForm";
import SolicitudEmpresa from "../../features/solicitudEmpresa/SolicitudEmpresa";
import SolicitudUsuario from "../../features/solicitudUsuario/SolicitudUsuario";
import { RouteConfigProps } from "./routeConfigInterface";
import PageTitle from "../../shared/components/pageTitle/PageTitle";
import React, { ReactElement, useEffect, useState } from "react";
import NotFound from "../../features/notFound/NotFound";
import Question from "../../features/questions/Question";
import Empresas from "../../features/empresas/Empresas";

const routerConfig: RouteConfigProps[] = [
  { path: "/", index: true, component: <Home />, title: "Auditrix" },
  {
    path: "/usuariosinfo",
    index: false,
    component: <SolicitudUsuario />,
    title: "Auditrix",
  },
  {
    path: "/infoaplicar",
    index: false,
    component: <SolicitudEmpresa />,
    title: "Auditrix",
  },
  {
    path: "/crearusuario",
    index: false,
    component: <NewUserCompanyForm />,
    title: "Auditrix",
  },
  {
    path: "/login",
    index: false,
    component: <Login />,
    title: "Auditrix Login",
  },
  {
    path: "/questions",
    index: false,
    component: <Question />,
    title: "Preguntas",
  },
  {
    path: "/empresas",
    index: false,
    component: <Empresas />,
    title: "Empresas",
  },
];

export default function Routers() {
  const [rutas, setRutas] = useState<React.ReactNode>();

  const handleSetRutas = () => {
    var ruta = routerConfig.map((e) => (
      <Route
        key={e.path}
        element={<PageTitle children={e.component} title={e.title} />}
        path={e.path}
        index={e.index}
      />
    ));

    setRutas(ruta);
  };
  useEffect(() => {
    handleSetRutas();
  }, []);

  return (
    <Routes>
      {rutas}
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}
