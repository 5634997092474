import ApiService from "../../apiService/api";
import { BaseResponse } from "../../wrappers/BaseResponse";
import { DeleteAnswersRequest, SaveOrUpdateAnswerRequest } from "./Contracts";

const urlBase = "respuesta";
const endpoints = {
    SaveOrUpdateAnswer: 'SaveOrUpdateAnswer',
    DeleteAnswerById: 'DeleteAnswerById'
}

const SaveOrUpdateAnswer = async (data: SaveOrUpdateAnswerRequest[]) : Promise<BaseResponse<number> | undefined> =>{
    let finalUrl = urlBase+"/"+endpoints.SaveOrUpdateAnswer;
    var response = await ApiService.post<number>(finalUrl, null, data);

    return response;
}

const DeleteAnswerById = async (data: DeleteAnswersRequest) : Promise<BaseResponse<number> | undefined> =>{
    let finalUrl = urlBase+"/"+endpoints.DeleteAnswerById;
    var response = await ApiService.post<number>(finalUrl, null, data);

    return response;
}

const GetUrlComplete = {
    SaveOrUpdateAnswer: `${urlBase}/${endpoints.SaveOrUpdateAnswer}`.toLowerCase(),
    DeleteAnswerById: `${urlBase}/${endpoints.DeleteAnswerById}`.toLowerCase()
}

export const AnswerService = {
    SaveOrUpdateAnswer,
    DeleteAnswerById,
    GetUrlComplete
}