import React from "react";

const Container = ({ children, title }: ContainerProps) => {
  return (
    <div className="p-10">
      <h1 className="text-2xl font-bold mb-4">{title ?? ""}</h1>

        {children}
    </div>
  );
};

export default Container;

interface ContainerProps {
  children: React.ReactNode;
  title?: string;
}
