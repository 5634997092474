import { useState } from "react";
import { Link } from "react-router-dom";

const Aside = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      {/* Barra superior */}
      <div className="fixed sm:hidden top-0 left-0 -z-10 bg-cyan-900 p-4 flex justify-between items-center w-full">
        <a
          href="index.html"
          className="text-white text-2xl font-semibold uppercase hover:text-gray-300"
        >
          Admin
        </a>
        <button
          onClick={toggleSidebar}
          className="text-white focus:outline-none"
        >
          {isOpen ? (
            <i className="fas fa-times text-2xl"></i> // Icono de cruz
          ) : (
            <i className="fas fa-bars text-2xl"></i> // Icono de hamburguesa
          )}
        </button>
      </div>

      {/* Sidebar */}
      <aside className={`bg-sidebar h-screen w-64 sm:block shadow-xl z-10 bg-cyan-900 fixed sm:fixed top-0 left-0  transition-transform transform ${isOpen ? 'translate-x-0' : '-translate-x-full'} sm:translate-x-0`}>

        <div className="p-6">
          <a
            href="index.html"
            className="text-white text-3xl font-semibold uppercase hover:text-gray-300"
          >
            Admin
          </a>
          <Link to="/crearusuario">
            <button className="w-full bg-white cta-btn font-semibold py-2 mt-5 rounded-br-lg rounded-bl-lg rounded-tr-lg shadow-lg hover:shadow-xl hover:bg-gray-300 flex items-center justify-center">
              <i className="fas fa-plus mr-3"></i> Alta Usuario
            </button>
          </Link>
        </div>
        <nav className="text-white text-base font-semibold pt-3">
          <Link to="/">
            <p className="flex items-center text-white opacity-75 hover:opacity-100 py-4 pl-6 nav-item">
              <i className="fas fa-tachometer-alt mr-3"></i>
              Usuarios Activos
            </p>
          </Link>

          <Link to="/usuariosinfo">
            <p className="flex items-center text-white opacity-75 hover:opacity-100 py-4 pl-6 nav-item ">
              <i className="fas fa-sticky-note mr-3"></i>
              Solicitaron Información
            </p>
          </Link>

          <Link to="/infoaplicar">
            <p className="flex items-center text-white opacity-75 hover:opacity-100 py-4 pl-6 nav-item">
              <i className="fas fa-align-left mr-3"></i>
              Solicitaron <br /> Auditoria
            </p>
          </Link>

          <Link to="/questions">
            <p className="flex items-center text-white opacity-75 hover:opacity-100 py-4 pl-6 nav-item">
              <i className="fas fa-align-left mr-3"></i>
              Preguntas
            </p>
          </Link>

          <Link to="/empresas">
            <p className="flex items-center text-white opacity-75 hover:opacity-100 py-4 pl-6 nav-item">
              <i className="fas fa-align-left mr-3"></i>
              Empresas
            </p>
          </Link>
        </nav>
        <a
          href="#"
          className="absolute w-full upgrade-btn bottom-0 active-nav-link text-white flex items-center justify-center py-4"
        >
          <i className="fas fa-arrow-circle-up mr-3"></i>
          Cerrar Sesión
        </a>
      </aside>

      {/* Overlay para cerrar el sidebar en mobile */}
      {isOpen && (
        <div
          className="fixed  inset-0 bg-black opacity-50 sm:hidden"
          onClick={toggleSidebar}
        ></div>
      )}
    </>
  );
};

export default Aside;
