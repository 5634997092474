const SimpleCard = ({children, title, className} : SimpleCardProps) => {
  return (
    <div
      className={`${className} block max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100`}
    >
      <h5 className="mb-2 text-[1.1rem] font-bold tracking-tight text-gray-900">
        {title}
      </h5>
      <div className="font-normal text-gray-700">
        {children}
      </div>
    </div>
  );
};

export default SimpleCard;

interface SimpleCardProps{
    title: string;
    children: React.ReactNode;
    className?: string;
}