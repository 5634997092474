import EditIcon from "@mui/icons-material/Edit";
import { useEffect, useState } from "react";
import { modalService } from "../../shared/components/modal/ModalService";
import TableResponsive, {
  TableOnActionProps,
} from "../../shared/components/tables/TableResponsive";
import { GetQuestionByTeethIdFilterResponseDto } from "../../shared/services/preguntas/Contracts";
import { QuestionService } from "../../shared/services/preguntas/QuestionService";
import { useAppDispatch, useAppSelector } from "../../shared/store/hooks";
import { connectionPendings } from "../../shared/store/slices/loadingConnectionSlice";
import QuestionFilterForm, {
  QuestionFilterFormProps,
} from "./components/QuestionFilterForm";
import { SetQuestionSelected } from "../../shared/store/slices/questions/currentQuestionSelectedSlice";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import MoreIcon from "../../shared/IconSvg/More/MoreIcon";
import { SetTeethSelected } from "../../shared/store/slices/teeth/currentTeethSelectedSlice";
import Container from "../../shared/components/containerTemplate/Container";

//Constante donde establecemos el icono de los botones
const BUTTONS = {
  editar: <EditIcon />,
  eliminar: <DeleteForeverIcon />,
};
//Constante donde se especifica que columna obtendra dato el boton
const BUTTON_RETURN = {
  editar: "id",
  eliminar: "id",
};
//Constante donde obtendremos informacion de los botones
const BUTTON_SELECT = {
  editar: "editar",
  eliminar: "eliminar",
};
//Constante donde excluiremos las columnas
const EXCLUDED_COLUMNS = ["id"];
//Constante que almacena el diente por defecto que se mostrara
const DEFAULT_TEETHID = 1;

const Question = () => {
  const dispatch = useAppDispatch();
  const loadingConnections = useAppSelector(connectionPendings);
  const [questionsAll, setQuestionAll] = useState<
    GetQuestionByTeethIdFilterResponseDto[] | undefined
  >([]);
  const [currentTeeth, setCurrentTeeth] = useState<number>(DEFAULT_TEETHID);

  const HandleGetQuestions = async (teethId: number = DEFAULT_TEETHID) => {
    var response = await QuestionService.GetQuestionByTeethIdFilter(teethId);

    if (!response?.hasError && response?.data) {
      setQuestionAll(response.data);
    }
  };

  const handleActionButton = (values: TableOnActionProps) => {
    if (values.button === BUTTON_SELECT.editar) {
      dispatch(SetQuestionSelected({ id: Number(values.value) }));
      modalService.QuestionEdit();
    }

    if (values.button === BUTTON_SELECT.eliminar) {
      dispatch(SetQuestionSelected({ id: Number(values.value) }));
      modalService.QuestionDelete();
    }
  };

  const handleSubmit = (values: QuestionFilterFormProps) => {
    HandleGetQuestions(values.teethId);
    setCurrentTeeth(values.teethId);
  };

  const handleNewQuestion = () => {
    dispatch(SetTeethSelected({ id: currentTeeth }));
    modalService.QuestionAdd();
  };

  useEffect(() => {
    HandleGetQuestions();
  }, []);

  return (
    <Container title="Grilla con preguntas y respuestas">
      <div>
        <QuestionFilterForm onSubmit={(values) => handleSubmit(values)} />
      </div>
      <div className="flex justify-end">
        <button
          onClick={() => handleNewQuestion()}
          className="p-3 text-white bg-green-600 rounded py-2 px-3 mb-3 flex items-center"
        >
          <MoreIcon />
          Crear pregunta
        </button>
      </div>
      <div className="">
        <TableResponsive
          values={questionsAll}
          actions={BUTTONS}
          actionReturn={BUTTON_RETURN}
          onAction={(value) => handleActionButton(value)}
          removeColumn={EXCLUDED_COLUMNS}
          isLoading={loadingConnections.includes(
            QuestionService.GetUrlComplete.GetQuestionByTeethIdFilter
          )}
        />
      </div>
    </Container>
  );
};

export default Question;
