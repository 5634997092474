import ApiService from "../../apiService/api";
import { BaseResponse } from "../../wrappers/BaseResponse";
import {  CrearEmpresaRequest, GetAllCompanyResponseDto, GetResultsByCompanyIdResponseDto } from "../../../shared/services/empresas/Contracts";

const urlBase = "empresa";
const endpoints = {
    GetAllCompanies: 'GetAllCompanies',
    GetResultsByCompanyId: 'GetResultsByCompanyId',
    Create: 'Create'
}

const GetAllCompanies = async (page: number, pageSize: number) : Promise<BaseResponse<GetAllCompanyResponseDto[]>| undefined> =>{
    let finalUrl = urlBase+"/"+endpoints.GetAllCompanies;
    var response = await ApiService.get<GetAllCompanyResponseDto[]>(finalUrl, {page, pageSize});
    return response;
}

const GetResultsByCompanyId = async (companyId: number) : Promise<BaseResponse<GetResultsByCompanyIdResponseDto[]> | undefined> =>{
    let finalUrl = urlBase+"/"+endpoints.GetResultsByCompanyId;
    var response = await ApiService.get<GetResultsByCompanyIdResponseDto[]>(finalUrl, {companyId})

    return response;
}

const Create = async (data: CrearEmpresaRequest) : Promise<BaseResponse<number>| undefined> =>{
    let finalUrl = urlBase+"/"+endpoints.Create;
    var response = await ApiService.post<number>(finalUrl, null, data)
    return response;
}

const GetUrlComplete = {
    GetAllCompanies: `${urlBase}/${endpoints.GetAllCompanies}`.toLowerCase(),
    GetResultsByCompanyId: `${urlBase}/${endpoints.GetResultsByCompanyId}`.toLowerCase(),
    Create: `${urlBase}/${endpoints.Create}`.toLowerCase(),

}

export const EmpresasService = {
    GetAllCompanies,
    GetResultsByCompanyId,
    GetUrlComplete,
    Create
}